<template>
  <section class="section-6">
    <div class="content">
      <div class="title">社会价值：厕所革命，智慧中国！</div>
      <div class="line">
        <div class="line-color" />
      </div>
      <ul class="item">
        <li v-for="(item, index) in valueList" :key="index">
          <div class="icon">
            <img
              :src="
                require('../../../assets/images/home/' +
                  item.imgIconUrl +
                  '.svg')
              "
              alt=""
            />
          </div>
          <div class="title">{{ item.title }}</div>
          <div class="text">{{ item.text }}</div>
          <div class="bg-num">
            <img
              :src="
                require('../../../assets/images/home/' +
                  item.imgNumUrl +
                  '.png')
              "
              alt=""
            />
          </div>
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
export default {
  name: "Slide1",
  components: {},
  data() {
    return {
      valueList: [
        {
          imgIconUrl: "value-icon-1",
          imgNumUrl: "value-num-1",
          title: "政策",
          text: "习总书记厕所革命指示，社会共建，全民共享，建设健康向上的厕所文化",
        },
        {
          imgIconUrl: "value-icon-2",
          imgNumUrl: "value-num-2",
          title: "环保",
          text: "一台出纸机一年可省200卷纸，理论上1颗树可生产70盘卷纸。1万台出纸机一年可节约树木28571.43棵",
        },
        {
          imgIconUrl: "value-icon-3",
          imgNumUrl: "value-num-3",
          title: "减负",
          text: "商业物业中，人力和卷纸的消耗都是很大的开支，啦撒项目可以提升物业管理效率从而减少人力成本，同时控制卷纸浪费，降低消耗品的开支，为企业减负",
        },
        {
          imgIconUrl: "value-icon-4",
          imgNumUrl: "value-num-4",
          title: "文明",
          text: "用纸是一件小事，文明却是一件大事。厕所缺的不仅仅是纸，是一种文明，是一种厕所文化。啦撒为厕所文明贡献一份力量",
        },
        {
          imgIconUrl: "value-icon-5",
          imgNumUrl: "value-num-5",
          title: "体验",
          text: "啦撒以用户为中心，通过线下出纸机设备与线上小程序结合，需要帮助轻松一点，提升用户如厕体验",
        },
        {
          imgIconUrl: "value-icon-6",
          imgNumUrl: "value-num-6",
          title: "效率",
          text: "啦撒运维端的奖励机制，让2200多万保洁人员有更多收益，提高保洁人员运维效率和积极性",
        },
      ],
    };
  },
};
</script>

<style scoped lang="less">
.section-6 {
  position: relative;
  width: 100vw;
  height: 100%;
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  .content {
    margin: 0 auto;
    width: 1200px;
    height: 568px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    > .title {
      height: 28px;
      font-size: 20px;
      font-weight: bold;
      line-height: 28px;
    }

    .line {
      margin-top: 40px;
      width: 1200px;
      height: 4px;
      background: #f2f4f8;
      position: relative;

      .line-color {
        width: 100%;
        height: 4px;
        background: #fe690c;
      }
    }

    .item {
      display: flex;
      flex-wrap: wrap;
      li {
        width: 344px;
        height: 191px;
        margin: 48px 0 0 82px;
        position: relative;
        .icon {
          width: 62px;
          height: 62px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .title {
          margin: 6px 0 0 9px;
          height: 28px;
          font-size: 20px;
          font-weight: bold;
          line-height: 28px;
        }
        .text {
          text-align: justify;
          margin: 16px 0 0 9px;
          width: 290px;
          font-size: 14px;
          color: #555555;
          line-height: 20px;
          position: relative;
          z-index: 99;
        }
        .bg-num {
          position: absolute;
          bottom: 0;
          right: 0;
          img {
            width: 136px;
            height: 146px;
          }
        }
      }
      li:nth-child(3n + 1) {
        margin-left: 0;
      }
      li:nth-child(n + 4) {
        margin-top: 82px;
      }
    }
  }
}
</style>
